import { Injectable } from "@angular/core";
import { State, Selector, StateContext, Action } from "@ngxs/store";
import { firstValueFrom } from "rxjs";
import { KlicAttachment, ExtraDetails } from "../interfaces/attachment.interface";
import { ApiService } from "../services/api.service";
import { AttachmentsStateModel, ListKlicAttachments, ListKlicExtraDetails } from "./models/attachments.state.model";

const defaultKlicAttachmentsState = {
      attachments: [],
      extraDetails: []
    }
      @State<AttachmentsStateModel>({
        name: 'klickAttachments',
        defaults: defaultKlicAttachmentsState
      })

      @Injectable()
      export class AttachmentState {
        @Selector([AttachmentState])
        static attachments(state: AttachmentsStateModel): KlicAttachment[] {
          return state.attachments
        }

        @Selector([AttachmentState])
        static extraDetails(state: AttachmentsStateModel): ExtraDetails[] {
          return state.extraDetails
        }

        @Selector([AttachmentState])
        static allAttachments(state: AttachmentsStateModel): any[] {
          return [...state.attachments, ...state.extraDetails];
        }
        
        constructor(
          private apiService: ApiService
        ) { }

        @Action(ListKlicAttachments)
        async listKlicAttachments(ctx: StateContext<AttachmentsStateModel>, action: ListKlicAttachments) {
          const attachmentsData = await firstValueFrom(this.apiService.listKlicAttachments(action.payload.uploadId));
          ctx.patchState({attachments: attachmentsData});
        }


        @Action(ListKlicExtraDetails) 
          async listKlicExtraDetails(ctx: StateContext<AttachmentsStateModel>, action: ListKlicExtraDetails) {
            const extraDetailsData = await firstValueFrom(this.apiService.listKlicExtraDetails(action.payload.uploadId));

            ctx.patchState({extraDetails: extraDetailsData})
          }
      }